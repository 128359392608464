export function SalmIcon({stroke, width, height}) {
  // 👇️ paste SVG into a component
  // take fill and stroke colors as props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 985.462 826.62">
  <g id="Group_4" data-name="Group 4" transform="translate(2.066 2.037)">
    <path id="Path_110" data-name="Path 110" d="M302.543,1474.951s18.286-117.608,40.3-105.189,81.438,132.427,81.438,132.427" transform="translate(49.995 -1355.453)" fill="none" stroke={stroke} stroke-width="4"/>
    <ellipse id="Ellipse_38" data-name="Ellipse 38" cx="268.949" cy="280.155" rx="268.949" ry="280.155" transform="translate(72.359 108.006)" fill="none"/>
    <path id="Path_111" data-name="Path 111" d="M0,81.655S229.2-1.186,337.461.014c.307,0,4.291-13.015,18.758-20.522,32.325-18.767,68.344,11.874,62.944,42.878-.977,7.622-6.894,26.242-30.576,35.614-.269.36-4.148.909-4.361,1.366-52.028,111.324-303.04,274.5-303.04,274.5" transform="matrix(0.995, 0.105, -0.105, 0.995, 561.028, 117.795)" fill="none" stroke={stroke} stroke-width="4"/>
    <g id="Ellipse_39" data-name="Ellipse 39" transform="matrix(0.995, 0.105, -0.105, 0.995, 894.117, 124.742)" fill="none" stroke={stroke} stroke-width="4">
      <ellipse cx="44.825" cy="44.825" rx="44.825" ry="44.825" stroke="none"/>
      <ellipse cx="44.825" cy="44.825" rx="42.825" ry="42.825" fill="none"/>
    </g>
    <g id="Ellipse_40" data-name="Ellipse 40" transform="translate(276.65 208.733)" fill="none" stroke={stroke} stroke-width="4">
      <ellipse cx="44.825" cy="50.428" rx="44.825" ry="50.428" stroke="none"/>
      <ellipse cx="44.825" cy="50.428" rx="42.825" ry="48.428" fill="none"/>
    </g>
    <path id="Path_112" data-name="Path 112" d="M777.493,1487.729s-128.728-79.216-313.916-59.134" transform="translate(-258.901 -1313.722)" fill="none" stroke={stroke} stroke-width="4"/>
    <path id="Path_113" data-name="Path 113" d="M617.157,1524.961c-.46.167-144.952,60.9-141.451,77.117.8,6.6,39.808,7.592,147.981-9.5" transform="translate(-8.488 -1067.478)" fill="none" stroke={stroke} stroke-width="4"/>
    <path id="Path_114" data-name="Path 114" d="M259.4,1541.9s64.056-192.134,91.549-172.609,77.568,154.282,77.568,154.282" transform="translate(-228.509 -1367.901)" fill="none" stroke={stroke} stroke-width="4"/>
    <path id="Path_115" data-name="Path 115" d="M30.318,8.6S-25.89,101.211,14.144,93.2,70.425,24.715,70.425,24.715s16.2,84.344,46.917,60.957,3.729-65.45,3.729-65.45Z" transform="translate(253.154 729.509) rotate(-8)" fill="none" stroke={stroke} stroke-width="4"/>
    <path id="Subtraction_4" data-name="Subtraction 4" d="M321.341,120.363h-.026c-35.034,0-72.662-1.918-111.839-5.7-32.487-3.134-65.526-7.483-98.2-12.926-25.6-4.266-50.763-9.209-70.842-13.92-16.422-3.853-28.106-7.259-29.768-8.678C8.591,77.368,6.737,72.1,5.158,63.495,3.9,56.608,2.806,47.577,1.918,36.654.4,17.947,0,.177,0,0,.055.026,7.464,3.52,21,8.644,33.5,13.376,53.949,20.516,80.4,27.66a792.758,792.758,0,0,0,92.42,19.017,838.1,838.1,0,0,0,120.05,8.644,845.943,845.943,0,0,0,96.748-5.576A855.524,855.524,0,0,0,495.291,30.7a252.826,252.826,0,0,1,13.817,27.061,172.856,172.856,0,0,1,10.336,31.148l-.178,1.242C469.1,110.2,402.508,120.363,321.341,120.363Z" transform="translate(0.003 613.88)" fill="none" stroke={stroke} stroke-width="4"/>
  </g>
</svg>


  );
}

