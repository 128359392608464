import {SalmIcon} from './assets/SalmReturn.js'
import {Helmet} from "react-helmet";
import './App.css';
import './fonts/HelveticaNeueMedium.otf'

import useWindowDimensions from './Utility'

function App() {

  const { height, width } = useWindowDimensions();

  
  
  return (
    <div className="App">

    {/* Header Information */}

      <Helmet>
          <meta charSet="utf-8" />
          <title>salm club</title>
          <link rel="canonical" href="http://salm.club" />
          <meta name="description" content="Journaling tool" />
      </Helmet>

      {/* App */}

      <div className="App-wrapper">
        <div>
            <SalmIcon stroke="black" width={height*0.3} height={height*0.3}/>
            </div>
            <h1 className="Title" >
              journal 
            </h1>
            <p className="Question" style={{maxWidth: height*0.3}}>
            what could you do  
            now to make you feel
            accomplished tonight?
            </p>
      </div>
    </div>
  );
}

export default App;
